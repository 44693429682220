.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  padding: 2rem;
  background-color: rgba(255, 248, 220, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.loading-spinner {
  position: relative;
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
}

.spinner-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 6px solid #8B4513;
  border-top: 6px solid #DAA520;
  border-radius: 50%;
  animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading-message {
  font-family: 'Cinzel', serif;
  color: #8B4513;
  font-size: 1.2rem;
  margin-top: 1rem;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .loading-spinner {
    width: 48px;
    height: 48px;
  }

  .loading-message {
    font-size: 1rem;
  }
}
