.error-message {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 1rem auto;
  padding: 1rem;
  background-color: rgba(255, 248, 220, 0.95);
  border-radius: 8px;
  border-left: 4px solid #ff4444;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.error-message.critical {
  border-left-color: #ff0000;
  background-color: rgba(255, 240, 240, 0.95);
}

.error-content {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.error-icon {
  font-size: 1.5rem;
  flex-shrink: 0;
  padding-top: 0.25rem;
}

.error-text {
  flex-grow: 1;
}

.error-text h3 {
  font-family: 'Cinzel', serif;
  margin: 0 0 0.5rem 0;
  color: #8B4513;
  font-size: 1.2rem;
}

.error-text p {
  margin: 0;
  color: #666;
  font-size: 1rem;
  line-height: 1.4;
}

.error-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.retry-button,
.dismiss-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Cinzel', serif;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.retry-button {
  background-color: #8B4513;
  color: #FFF8DC;
}

.retry-button:hover {
  background-color: #DAA520;
}

.dismiss-button {
  background-color: transparent;
  color: #8B4513;
  border: 1px solid #8B4513;
}

.dismiss-button:hover {
  background-color: rgba(139, 69, 19, 0.1);
}

.error-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #8B4513;
  transform-origin: left;
  animation: progress-shrink linear forwards;
}

@keyframes progress-shrink {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .error-message {
    margin: 1rem;
    padding: 0.75rem;
  }

  .error-content {
    flex-direction: column;
    gap: 0.5rem;
  }

  .error-icon {
    font-size: 1.25rem;
  }

  .error-text h3 {
    font-size: 1.1rem;
  }

  .error-text p {
    font-size: 0.9rem;
  }

  .error-actions {
    flex-direction: column;
  }

  .retry-button,
  .dismiss-button {
    width: 100%;
  }
}
