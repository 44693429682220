/* Подключение шрифтов */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Merriweather:wght@400;700&display=swap');

/* Базовые стили */
:root {
  --primary-color: #8B4513;
  --secondary-color: #DAA520;
  --bg-color: #FFF8DC;
  --text-color: #8B4513;
  --border-radius: 5px;
  --transition-speed: 0.3s;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Merriweather', serif;
  min-height: 100vh;
}

h1, h2 {
  font-family: 'Cinzel', serif;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

/* Основной контейнер */
.App {
  text-align: center;
  padding: 2rem 1rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

/* Заголовок выбора квеста */
.choose-quest-title {
  font-size: 2.5rem;
  text-align: center;
  margin: 2rem 0;
  color: var(--secondary-color);
}

/* Список квестов */
.quest-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Кнопки */
.quest-button,
button[type="submit"],
.styled-button,
.not-found button,
.login-container button,
.register-container button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--bg-color);
  border: none;
  border-radius: var(--border-radius);
  font-family: 'Cinzel', serif;
  transition: all var(--transition-speed);
  text-decoration: none;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  margin: 0.5rem 0;
  width: 100%;
  max-width: 400px;
}

.quest-button:hover,
button[type="submit"]:hover,
.styled-button:hover,
.not-found button:hover,
.login-container button:hover,
.register-container button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-1px);
}

/* Формы ввода */
input[type="text"],
input[type="file"],
input[type="email"],
input[type="password"],
.login-container input,
.register-container input {
  padding: 0.75rem;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius);
  width: 100%;
  max-width: 400px;
  margin: 0.5rem 0;
  font-family: 'Merriweather', serif;
}

input:focus {
  outline: none;
  border-color: var(--secondary-color);
  box-shadow: 0 0 5px rgba(218, 165, 32, 0.3);
}

/* Контейнеры авторизации */
.login-container,
.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  margin: 2rem auto;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-container form,
.register-container form {
  width: 100%;
  max-width: 400px;
}

/* Анимации и эффекты */
.deducted-points-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: red;
  animation: fade-out 1.5s ease-out;
  z-index: 1000;
  pointer-events: none;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2);
  }
}

/* Загрузка и ошибки */
.global-loading,
.error-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 248, 220, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.not-found {
  text-align: center;
  padding: 2rem;
  margin: 2rem auto;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* My Account кнопка */
.my-account-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 100;
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-color);
  color: var(--bg-color);
  border: none;
  border-radius: var(--border-radius);
  font-family: 'Cinzel', serif;
  cursor: pointer;
  transition: all var(--transition-speed);
}

.my-account-button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-1px);
}

/* Медиа-запросы для адаптивности */
@media screen and (max-width: 768px) {
  .App {
    padding: 1rem;
  }

  .choose-quest-title {
    font-size: 2rem;
    margin: 1rem 0;
  }

  .quest-list {
    padding: 1rem;
    margin: 1rem auto;
  }

  .quest-button,
  button[type="submit"],
  .styled-button {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }

  .login-container,
  .register-container {
    padding: 1rem;
    margin: 1rem auto;
  }

  input[type="text"],
  input[type="file"],
  input[type="email"],
  input[type="password"] {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .my-account-button {
    position: static;
    width: 100%;
    max-width: 400px;
    margin: 1rem auto;
  }
}

/* Дополнительные медиа-запросы для маленьких экранов */
@media screen and (max-width: 480px) {
  .choose-quest-title {
    font-size: 1.5rem;
  }

  .quest-button,
  button[type="submit"],
  .styled-button {
    padding: 0.5rem;
    font-size: 0.8rem;
  }

  .login-container,
  .register-container {
    padding: 0.5rem;
  }

  .deducted-points-animation {
    font-size: 1.5rem;
  }
}

/* Поддержка темной темы */
@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #2C1810;
    --text-color: #FFF8DC;
  }

  .quest-list,
  .login-container,
  .register-container,
  .not-found {
    background-color: rgba(0, 0, 0, 0.7);
  }

  input {
    background-color: rgba(255, 248, 220, 0.1);
    color: var(--text-color);
  }
}
