@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 24.6 95% 53.1%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 24.6 95% 53.1%;
    --radius: 0.75rem;
    
    /* Custom transition variables */
    --transition-duration: 500ms;
    --transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
    --enter-transform: translateY(16px);
    --exit-transform: translateY(-16px);
  }
  
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 20.5 90.2% 48.2%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-background text-foreground;
    overflow-x: hidden; /* Prevent horizontal scroll during animations */
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  /* Шрифты для русского текста */
  html[lang="ru"], 
  html:lang(ru), 
  .text-ru {
    font-family: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  }
  
  /* Заголовки на русском */
  html[lang="ru"] h1, 
  html:lang(ru) h1,
  .text-ru h1,
  html[lang="ru"] h2, 
  html:lang(ru) h2,
  .text-ru h2,
  .text-ru .font-serif {
    font-family: 'PT Serif', Georgia, serif;
    font-weight: 700;
  }
  
  /* Улучшенная читаемость текста */
  p {
    letter-spacing: 0.01em;
    line-height: 1.6;
  }
  
  /* Стили для русского текста */
  .text-ru p {
    letter-spacing: 0.02em;
    line-height: 1.5;
    text-align: left;
  }
  
  /* Улучшенные отступы абзацев */
  .prose p + p {
    margin-top: 1em;
  }
  
  /* Красная строка для русского текста (опционально) */
  .text-ru p:not(.no-indent) {
    text-indent: 1.5em;
  }
  
  /* Настройка блоков цитат */
  blockquote {
    margin: 1.5em 0;
    padding: 0.5em 0 0.5em 1em;
    border-left: 4px solid theme('colors.primary.DEFAULT');
    font-style: italic;
    background-color: rgba(0, 0, 0, 0.03);
  }
  
  /* Выравнивание текстового контента по левому краю */
  .text-content p, 
  .text-content h1, 
  .text-content h2, 
  .text-content h3, 
  .text-content blockquote,
  .prose p,
  .prose h1, 
  .prose h2, 
  .prose h3, 
  .prose blockquote,
  .text-ru p,
  .text-ru h1, 
  .text-ru h2 {
    text-align: left;
  }
  
  /* Заголовки с улучшенными стилями */
  .heading-1, h1.formatted {
    @apply text-xl md:text-2xl font-bold text-primary mb-4 text-left;
  }
  
  .heading-2, h2.formatted {
    @apply text-lg md:text-xl font-semibold text-primary mb-3 text-left;
  }
  
  /* Отступы для списков */
  ul.formatted, ol.formatted {
    @apply pl-6 mb-4;
  }
  
  ul.formatted li, ol.formatted li {
    @apply mb-2;
  }
  
  ul.formatted {
    @apply list-disc;
  }
  
  ol.formatted {
    @apply list-decimal;
  }
  
  /* Стили для русских заголовков квеста */
  .quest-title.text-ru {
    font-family: 'PT Serif', Georgia, serif;
    font-weight: 700;
    letter-spacing: 0.02em;
  }
}

/* Animation keyframes */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-10px); }
}

@keyframes slideUp {
  from { transform: translateY(30px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-30px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideLeft {
  from { transform: translateX(30px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideRight {
  from { transform: translateX(-30px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes pulseSuccess {
  0% { box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.4); }
  70% { box-shadow: 0 0 0 15px rgba(34, 197, 94, 0); }
  100% { box-shadow: 0 0 0 0 rgba(34, 197, 94, 0); }
}

@keyframes pulseError {
  0% { box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.4); }
  70% { box-shadow: 0 0 0 15px rgba(239, 68, 68, 0); }
  100% { box-shadow: 0 0 0 0 rgba(239, 68, 68, 0); }
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

@keyframes scale {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Animation utilities */
@layer utilities {
  /* Stage animation phases */
  .stage-hidden {
    opacity: 0;
    transform: var(--enter-transform);
    pointer-events: none;
  }
  
  .stage-entering {
    opacity: 0;
    transform: var(--enter-transform);
    transition: 
      opacity var(--transition-duration) var(--transition-timing),
      transform var(--transition-duration) var(--transition-timing);
  }
  
  .stage-visible {
    opacity: 1;
    transform: translateY(0);
    transition: 
      opacity var(--transition-duration) var(--transition-timing),
      transform var(--transition-duration) var(--transition-timing);
  }
  
  .stage-exiting {
    opacity: 0;
    transform: var(--exit-transform);
    transition: 
      opacity var(--transition-duration) var(--transition-timing),
      transform var(--transition-duration) var(--transition-timing);
    pointer-events: none;
  }
  
  /* Text animation */
  .animate-fade-in {
    animation: fadeIn 0.6s ease-out forwards;
  }
  
  .animate-fade-out {
    animation: fadeOut 0.5s ease-out forwards;
  }
  
  .animate-slide-up {
    animation: slideUp 0.5s ease-out forwards;
  }
  
  .animate-slide-down {
    animation: slideDown 0.5s ease-out forwards;
  }
  
  .animate-slide-left {
    animation: slideLeft 0.5s ease-out forwards;
  }
  
  .animate-slide-right {
    animation: slideRight 0.5s ease-out forwards;
  }
  
  .animate-success {
    animation: pulseSuccess 1.5s ease-in-out;
  }
  
  .animate-error {
    animation: pulseError 1.5s ease-in-out;
  }
  
  .animate-scale {
    animation: scale 0.5s ease-in-out;
  }
  
  .animate-shimmer {
    background: linear-gradient(90deg, 
      transparent, 
      rgba(255, 255, 255, 0.2), 
      transparent
    );
    background-size: 200% 100%;
    animation: shimmer 2s infinite linear;
  }
}

/* Component styles */
@layer components {
  /* Improved card transitions */
  .card-transition {
    transition-property: opacity, transform, box-shadow, background-color;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform, opacity;
  }
  
  /* Audio player styles */
  .audio-progress {
    @apply w-full bg-gray-200 rounded-full h-2 overflow-hidden;
  }
  
  .audio-progress-bar {
    @apply bg-primary h-full transition-all duration-300;
    will-change: width;
  }
  
  .audio-btn {
    @apply rounded-full w-12 h-12 flex items-center justify-center transition-all duration-200;
  }
  
  .audio-btn:hover {
    @apply transform scale-105 shadow-md;
  }
  
  /* Image upload and preview */
  .image-dropzone {
    @apply transition-all duration-300 border-2 border-dashed rounded-lg p-6;
  }
  
  .image-dropzone-active {
    @apply border-primary bg-primary/10;
  }
  
  .image-preview {
    @apply relative rounded-lg overflow-hidden transition-all duration-300;
  }
  
  .image-preview-success {
    @apply ring-4 ring-green-500 ring-opacity-50;
    animation: pulseSuccess 1.5s ease-in-out;
  }
  
  /* Form elements improvement */
  .input-transition {
    @apply transition-all duration-300;
  }
  
  .input-success {
    @apply border-green-500 focus:ring-green-500;
  }
  
  .input-error {
    @apply border-red-500 focus:ring-red-500;
  }
  
  /* ======= BUTTON FIXES ======= */
  /* Login and register form buttons - specific fixes for auth screens */
  form button[type="submit"] {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    background-color: #9A6A38;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  form button[type="submit"]:hover {
    background-color: #805829;
  }
  
  /* Fix for inline-flex buttons with icon and text */
  button.inline-flex, 
  a.inline-flex,
  [class*="inline-flex"] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Add proper spacing between icon and text in button components */
  button.inline-flex > svg + *, 
  a.inline-flex > svg + *,
  [class*="inline-flex"] > svg + * {
    margin-left: 0.5rem;
  }
  
  /* Add proper spacing when text comes before icon */
  button.inline-flex > :not(svg) + svg, 
  a.inline-flex > :not(svg) + svg,
  [class*="inline-flex"] > :not(svg) + svg {
    margin-left: 0.5rem;
  }
  
  /* Override specific button types */
  .create-quest-btn {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    background-color: #9A6A38;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .create-quest-btn:hover {
    background-color: #805829;
  }
  
  .create-quest-btn > svg {
    margin-right: 0.5rem;
  }

  /* Fix Button component spacing issues */
  [class*="space-x-"] > * {
    margin-left: 0 !important;
  }
  
  [class*="space-x-"] > * + * {
    margin-left: var(--tw-space-x-reverse) !important;
  }
  
  /* Button states */
  .btn-state-transition {
    @apply transition-all duration-300;
  }
  
  .btn-state-success {
    @apply bg-green-600 hover:bg-green-700;
  }
  
  .btn-state-error {
    @apply bg-red-600 hover:bg-red-700;
  }
  
  /* Team member status */
  .member-status-online {
    @apply text-green-500;
  }
  
  .member-status-offline {
    @apply text-gray-400;
  }
  
  /* Stage transitions */
  .stage-transition-enter {
    opacity: 0;
    transform: translateY(10px);
  }
  
  .stage-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .stage-transition-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .stage-transition-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }
  
  /* Loading states */
  .loading-overlay {
    @apply fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50;
  }
  
  .loading-spinner {
    @apply animate-spin rounded-full h-10 w-10 border-4 border-primary border-opacity-25;
    border-top-color: hsl(var(--primary));
  }
  
  /* Улучшенный компонент для отображения текста с поддержкой русского языка */
  .text-content {
    @apply space-y-4;
  }
  
  .text-content.text-ru {
    font-family: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  }
  
  .text-content h1 {
    @apply text-xl md:text-2xl font-bold text-primary mb-4 text-left;
  }
  
  .text-content.text-ru h1 {
    font-family: 'PT Serif', Georgia, serif;
  }
  
  .text-content h2 {
    @apply text-lg md:text-xl font-semibold text-primary mb-3 text-left;
  }
  
  .text-content.text-ru h2 {
    font-family: 'PT Serif', Georgia, serif;
  }
  
  .text-content p {
    @apply mb-4 last:mb-0 text-left;
  }
  
  .text-content.text-ru p:not(.no-indent) {
    text-indent: 1.5em;
  }
  
  .text-content blockquote {
    @apply italic pl-4 border-l-4 border-primary/30 py-1 my-4 bg-gray-50 text-left;
  }
}

/* Utilities for socket status */
.socket-status-indicator {
  @apply flex items-center gap-1 text-sm px-2 py-1 rounded;
}

.socket-status-connected {
  @apply bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100;
}

.socket-status-connecting {
  @apply bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-100;
}

.socket-status-disconnected {
  @apply bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-100;
}

/* Quest content specific styling */
.quest-content {
  @apply space-y-6;
}

.quest-progress {
  @apply relative h-2 w-full bg-gray-200 rounded-full overflow-hidden;
}

.quest-progress-bar {
  @apply absolute left-0 top-0 h-full bg-primary transition-all duration-300;
}

/* Role-based styling */
.role-gamer {
  @apply text-green-500;
}

.role-listener {
  @apply text-blue-500;
}

.role-regular {
  @apply text-purple-500;
}

/* Helper for screen readers */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
